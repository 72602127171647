import React from "react";

const MapAdres = ({ dealers }) => {
  return (
    <div className="dealers__adres">
      {dealers.map((dealer, index) => {


        return (
          <div key={index}>
            <h2>{dealer.company_name}</h2>
            <ul className="mb-30">
              <li>
                <span>Адрес:</span> {dealer.business_address}
              </li>
              <li>
                <span>Тел.:</span> {dealer.phone}
              </li>
              <li>
                <span>E-mail:</span> {dealer.email}
              </li>
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default MapAdres;
