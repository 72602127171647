import * as React from "react";
import Layout from "../components/layout";
import SvgIcons from "../components/svgIcons/svgIcons";
import { Link } from "gatsby";
import MapSvg from "../components/Map/map";
import { useMediaQuery } from 'react-responsive';

const IndexPage = () => {
  const is700px = useMediaQuery({ query: '(min-width: 700px)' })
  return (
    <Layout>
      <div className="container content">
        <div className="content_header text-center mt-60">
          <h1 className="">ТРУБОПРОВОДНАЯ АРМАТУРА DENDOR</h1>
          <p className="p20">
            АО «ЭНЕРГИЯ» - российский производитель и поставщик надежной
            трубопроводной арматуры DENDOR.
          </p>
        </div>
        <div className="card flex">
          <div className="card_box">
            <div className="card_body">
              <div className="card_img">
                <SvgIcons width="64" height="64" name="2" />
              </div>
              <div className="card_text">
                <p>
                  <Link to="/zaklyuchenie-minpromtorga-o-podtverzhdenii-proizvodstva-dendor-v-rossii">
                    Полный цикл производства в Российской Федерации, заверенный
                    экспертным заключением МинПромТорга
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="card_line">
            <span></span>
          </div>
          <div className="card_box">
            <div className="card_body">
              <div className="card_img">
                <SvgIcons width="64" height="64" name="1" />
              </div>
              <div className="card_text">
                <p>
                  <Link to="/certificates">
                    Качество, подтвержденное декларациями, сертификатами,
                    экспертными заключениями
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="card_box">
            <div className="card_body">
              <div className="card_img">
                <SvgIcons width="64" height="64" name="3" />
              </div>
              <div className="card_text">
                <p>
                  <Link to="/catalog">
                    Широчайший ассортиментный ряд: затворы, задвижки, обратные
                    клапаны, фасонные части, фланцы, фитинги и многое другое
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="card_line">
            <span></span>
          </div>
          <div className="card_box">
            <div className="card_body">
              <div className="card_img">
                <SvgIcons width="64" height="64" name="7" />
              </div>
              <div className="card_text">
                <p>
                  <Link to="/10-vmesto-5">
                    Гарантийный срок эксплуатации 10 лет при соблюдении условий
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="card_box">
            <div className="card_body">
              <div className="card_img">
                <SvgIcons width="64" height="64" name="4" />
              </div>
              <div className="card_text">
                <p>
                  <Link to="/dealers">
                    Сеть дилеров и официальных представителей во всех крупных
                    городах России
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="card_line">
            <span></span>
          </div>
          <div className="card_box">
            <div className="card_body">
              <div className="card_img">
                <SvgIcons width="64" height="64" name="5" />
              </div>
              <div className="card_text">
                <p>
                  <Link to="/service">
                  Поддержка на всех этапах сотрудничества: от помощи при подборе до консультаций по монтажу и наладке
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="info mt-30 ">
          <p>
            Трубопроводная арматура DENDOR представлена на рынке более 30 лет.
            Арматура торговой марки DENDOR обеспечивает безопасную и
            бесперебойную работу предприятий коммунального хозяйства,
            водоканалов и их структур, а также теплосетевых, промышленных и
            других предприятий России.
          </p>
        </div>
        <div className="mt-30  text-center video-main">
          <h2 className="mb-30">Видеоролик о компании:</h2>
          <iframe

            src="https://www.youtube.com/embed/CoeuM_sgrVM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
        <div className="cardthree  mt-60">
          <div className="cardthree_box">
            <div className="cardthree_body">
              <div className="cardthree_img">
                <SvgIcons width="64" height="64" name="how" />
              </div>
              <div className="cardthree_header">
                <h5>Как сделать заказ?</h5>
              </div>
              <div className="cardthree_text">
                <p>
                  Для оформления заявки на поставку трубопроводной арматуры{" "}
                  <b>Dendor</b> Вы можете отправить запрос по e-mail, факсу или
                  через сайт.
                </p>
              </div>
            </div>
          </div>
          <div className="cardthree_box">
            <div className="cardthree_body">
              <div className="cardthree_img">
                <SvgIcons width="64" height="64" name="delivery" />
              </div>
              <div className="cardthree_header">
                <h5>Доставка</h5>
              </div>
              <div className="cardthree_text">
                <p>
                  Всем клиентам мы предлагаем доставку товара во все регионы
                  России как автомобильным, так и железнодорожным транспортом.
                </p>
              </div>
            </div>
          </div>
          <div className="cardthree_box">
            <div className="cardthree_body">
              <div className="cardthree_img">
                <SvgIcons width="64" height="64" name="reviews" />
              </div>
              <div className="cardthree_header">
                <h5>Отзывы</h5>
              </div>
              <div className="cardthree_text">
                <p>
                  Наши клиенты — это наша гордость и мы делаем все, чтобы
                  клиенты оставались довольны сотрудничеством с нами.
                </p>
              </div>
            </div>
          </div>
        </div>
       {/*  {is700px &&
        <div className="map mt-60 text-center">
          <h2 className="mb-30 ">Сеть дилеров и официальных представителей</h2>
          <MapSvg />
        </div>
        } */}
         <div className="map mt-60 text-center">
          <h2 className="mb-30 is-display700">Сеть дилеров и официальных представителей</h2>
          {is700px && <MapSvg />}
        </div>
        <div className="text-center mt-60">
          <h2 className="">БЫТЬ НАШИМ ДИЛЕРОМ:</h2>
          <div className="cardthree mt-30">
            <div className="cardthree_box">
              <div className="cardthree_body">
                <div className="cardthree_img">
                  <SvgIcons width="64" height="64" name="how" />
                </div>
                <div className="cardthree_header">
                  <h5>ПРИБЫЛЬНО</h5>
                </div>
                <div className="cardthree_text">
                  <p>
                    Бренд известен своим качеством в мире уже 30 лет! Качество
                    DENDOR подтверждено сертификатами, декларациями и
                    экспертными заключениями.
                  </p>
                </div>
              </div>
            </div>
            <div className="cardthree_box">
              <div className="cardthree_body">
                <div className="cardthree_img">
                  <SvgIcons width="64" height="64" name="delivery" />
                </div>
                <div className="cardthree_header">
                  <h5>ВЫГОДНО</h5>
                </div>
                <div className="cardthree_text">
                  <p>
                    Для наших партнеров мы всегда предлагаем лучшие условия:
                    динамические скидки, акции, бонусы, гибкую систему товарного
                    кредита.
                  </p>
                </div>
              </div>
            </div>
            <div className="cardthree_box">
              <div className="cardthree_body">
                <div className="cardthree_img">
                  <SvgIcons width="64" height="64" name="reviews" />
                </div>
                <div className="cardthree_header">
                  <h5>ПРОСТО</h5>
                </div>
                <div className="cardthree_text">
                  <p>
                    Мы всегда поддерживаем наших дилеров! Для Вас мы всегда
                    предоставляем техническую поддержку, консультации, рекламные
                    материалы.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="infobuy mt-60">
          <div className="infobuy_container container">
            <div className="infobuy_contacts">
              <h5>
                КУПИТЬ АРМАТУРУ DENDOR <br />В САНКТ-ПЕТЕРБУРГЕ:
              </h5>
              <p>+7 (812) 640-30-30</p>
              <p> OFFICE@DENDOR.RU</p>
            </div>
            <div className="infobuy_contacts">
              <h5>
                КУПИТЬ АРМАТУРУ DENDOR <br />В МОСКВЕ:
              </h5>
              <p>+7 (495) 640-65-72</p>
              <p> MSK@DENDOR.RU</p>
            </div>
          </div>
        </div>
        <div className="contactCenter text-center">
          <div className="contactCenter_body">
            <h5 className="mb-30">ТРУБОПРОВОДНАЯ АРМАТУРА DENDOR В РОССИИ:</h5>
            <p className="phone800">+7 (800) 333-40-30</p>
            <h5 className="mt-30">
              ЗВОНОК БЕСПЛАТНЫЙ ДЛЯ ВСЕХ РЕГИОНОВ РОССИИ
            </h5>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
