import React from "react";
import MapAdres from "./MapAdres";

const TooltipMap = ({ dealers, isToolptip, region, position }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: position.top,
        left: position.left,
      }}
      className={`tooltip ${isToolptip ? "" : "tooltip__display"}`}
    >
      <h2>{region}</h2>
      {dealers.length > 0 ? <MapAdres dealers={dealers} /> : ""}
    </div>
  );
};

export default TooltipMap;
